@import "../../../index.scss";

.goods {
  margin-left: getRem(20);
  margin-top: getRem(20);
  width: getRem(158);
  height: fit-content;
  border-radius: getRem(8);
  box-shadow: 0px 0px 13px 0px rgba(219,219,219,0.83);
  -webkit-box-shadow: 0px 0px 13px 0px rgba(219,219,219,0.83);
  -moz-box-shadow: 0px 0px 13px 0px rgba(219,219,219,0.83);
  padding: getRem(5) getRem(5);
  @include breakpoint(mobile_landscape) {
    padding: getRem(10) getRem(10);
    width: getRem(264);
    height: unset;
    margin-left: getRem(30);
  }
  img {
    object-fit: cover;
    width: 100%;
    height: getRem(100);
    @include breakpoint(mobile_landscape) {
      width: 100%;
      height: getRem(210);
    }
  }
  h4 {
    margin-top: getRem(5);
    font-size: getRem(16);
    line-height: getRem(16);
  }
  &-info {
    h4 {
      font-size: getRem(14);
    }
    @include breakpoint(mobile_landscape) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: getRem(15);
      margin-bottom: getRem(20);
      font-size: getRem(18);
      h4 {
        margin: 0;
      }
      span {
        opacity: 0.5;
      }
    }
  }
  &-price {
    font-size: getRem(14);
    color: #3c9806;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-old {
      font-size: getRem(12);
      color: rgb(156, 156, 156);
      text-decoration: line-through;
    }
    @include breakpoint(mobile_landscape) {
      font-size: getRem(18);
      &-old {
        font-size: getRem(13);
      }
    }
  }
  &-cart {
    padding: getRem(5);  
    background-color: white;
    border-radius: 50%;
    box-shadow: 0px 0px 10px 4px rgba(112,112,112,0.83);
    -webkit-box-shadow: 0px 0px 10px 4px rgba(112,112,112,0.83);
    -moz-box-shadow: 0px 0px 10px 4px rgba(112,112,112,0.83);
    position: absolute;
    margin-left: getRem(-30);
    color: white;
  }
  &:hover {
    box-shadow: 0px 0px 13px 5px rgba(189,189,189,0.83);
    -webkit-box-shadow: 0px 0px 13px 5px rgba(189,189,189,0.83);
    -moz-box-shadow: 0px 0px 13px 5px rgba(189,189,189,0.83);
  }
}

.goods-disabled {
  background-color: rgba(163, 163, 163, 0.534);
}

.goods-outofstock {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  font-size: getRem(16);
  color: #ec2640;
  background-color: white;
  margin-right: getRem(100);
  padding: getRem(1) getRem(5);
}
