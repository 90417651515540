@import "../../index.scss";

.about {
  h2 {
    font-size: getRem(20);
    margin: getRem(20) getRem(20);
    font-weight: 600;
    @include breakpoint(mobile_landscape) {
      text-align: center;
      font-weight: 600;
      margin-top: getRem(155);
      font-size: getRem(36);
    }
  }
  p {
    font-size: getRem(16);
    margin: 0 getRem(20) getRem(20);
    text-align: justify;
    @include breakpoint(mobile_landscape) {
      margin-left: getRem(147);
      margin-right: getRem(147);
    }
  }
  &-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      margin-top: getRem(30);
      margin-bottom: getRem(30);
      transition: transform .5s;
      width: getRem(300);
      &:hover {
        -webkit-box-shadow: 0px 0px 28px 5px rgba(0,0,0,0.49); 
        box-shadow: 0px 0px 28px 5px rgba(0,0,0,0.49);
        transform: scale(1.1);
      }
    }
    @include breakpoint(mobile_landscape) {
      margin-top: getRem(60);
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      margin-left: getRem(147);
      margin-right: getRem(147);
      img {
        transition: transform .5s;
        height: getRem(500);
        width: fit-content;
        &:hover {
          -webkit-box-shadow: 0px 0px 28px 5px rgba(0,0,0,0.49); 
          box-shadow: 0px 0px 28px 5px rgba(0,0,0,0.49);
          transform: scale(1.1);
        }
      }
    }
  }
}
