@import '../../index.scss';
.no-data {
    width: 100%;
    margin-top: 5%;
    height: 60vh;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}

.show-more {
    margin-top: getRem(50);
    display: flex;
    justify-content: center;
    align-items: center;
    button {
        cursor: pointer;
        background-color: transparent;
        border: getRem(1) solid #078943;
        border-radius: getRem(8);
        font-size: getRem(18);
        padding: getRem(10) getRem(15);
        color: #078943;
        &:hover {
            color: white;
            background-color: #078943;
        }
    }
}