@import "~react-image-gallery/styles/scss/image-gallery.scss";

@mixin breakpoint($point) {
  @if $point == mobile {
    @content;
  } @else if $point == mobile_landscape {
    @media screen and (min-width: 667px), screen and (min-aspect-ratio: 13/9) {
      @content;
    }
  } @else if $point == tablet {
    @media screen and (min-width: 768px) {
      @content;
    }
  } @else if $point == tablet_landscape {
    @media screen and (min-width: 960px), screen and (min-aspect-ratio: 13/9) {
      @content;
    }
  } @else if $point == desktop {
    @media screen and (min-width: 1025px) {
      @content;
    }
  }
}

@function getRem($size) {
  $remSize: $size / 16;
  @return #{$remSize}rem;
}

html {
  font-size: calc(16 * 100vw / 375);
  width: calc(0vw + 100%);

  @include breakpoint(mobile_landscape) {
    font-size: calc(10 * 100vw / 667);
  }

  @include breakpoint(tablet) {
    font-size: calc(12 * 100vw / 768);
  }

  @include breakpoint(tablet_landscape) {
    font-size: calc(14 * 100vw / 960);
  }

  @include breakpoint(desktop) {
    font-size: calc(16 * 100vw / 1440);
  }

  body {
    margin: 0;
    overflow-x: hidden;
  }
}

.ant-alert {
  position: fixed;
  z-index: 1000;
}
.alert {
  @include breakpoint(mobile_landscape) {
    position: fixed;
    z-index: 1000;
    width: getRem(500);
    text-align: center;
    height: getRem(70);
    .ant-alert-message {
      font-size: getRem(18);
  
    }
  }
  
}
