@import '../../../index.scss';

.preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background-color: #f4f7f4;
    img {
        width: 25vw;
    }
}